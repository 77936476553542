import { useApolloClient } from '@apollo/client'
import { navigate } from 'gatsby'
import { CheckBox, Grommet } from 'grommet'
import { decodeJwt } from 'jose'
import { Button, Close, IconButton } from 'nzk-react-components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme'
// @ts-ignore
import { ReactComponent as Tick } from '../../UI/Icons/Tick.svg'
// @ts-ignore
import SIGN_UP_AS_KID_CONSENT from './graphql/signUpAsKidConsent.graphql'
/*global amplitude*/

const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  aign-items: center;
  text-align: center;
  margin 0 auto;
  color: #fff;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  padding: 0 50px;
  margin 0 auto;
`

const Title = styled.div`
  font-family: 'Rammetto One';
  font-size: 21px;
  text-shadow: 0 3px 0 ${colors.gray1}, 0 5px 0 rgba(0,0,0,0.4);
  margin-top: 13px;
  margin-bottom: 18px;
`

const IconWrapper = styled.div`
  margin-left: 25px;
  >:first-child {
    margin-left: -25px;
    z-index: 2;
  }
  >:last-child {
    height: 35px;
    top: -44px;
  }
  cursor: pointer;
`

const Message = styled.p`
  font-size: 14px;
  text-align: left;
`

const ExpiredMessage = styled.p`
  font-size: 14px;
  // text-align: left;
`

const Info = styled(Message)`

`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  > :first-child {
    margin-right: 26px;
  }
`

const Delete = styled.div``

const ColumnActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    margin-bottom: 26px;
  }
  ${Delete} {
    margin-left: -140px;
    margin-top: 30px;
    > :first-child {
      margin-left: -25px;
    }
    > :last-child {
      position: absolute;
      margin-top: -45px;
    }
  }
`

const Box = styled.div`
  background-color: ${colors.purple3};
  padding: 22px 15px 40px 22px;
  border-radius: 20px;
`

const Form = styled.div``

const Group = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  * {
    box-shadow: none;
    outline: none;
    border-radius: 4px;
  }
`

const Label = styled(Message)`
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
`

const Link = styled.div`
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`

const theme = {
  checkBox: {
    color: '#fff',
    border: {
      color: 'none',
      width: '0px'
    },
    extend: () => `background-color: #fff; border-color: #000;`,
    check: {
      extend: () => `border-radius: 4px;`,
    },
    icon: {
      // size: '100%',
      color: '#fff',
      extend: () => `background-color: #8CC63F; stroke: #fff`,
    },
    toggle: {
      background: {
        color: '#fff'
      },
      color: '#fff',
    },
    hover: {
      border: { color: '#fff' },
      background: {
        color: '#fff'
      },
    },
  }
}

interface IProps {
  token: string
}

const ExpiredLink = () => {
  return <Wrapper>
    <Title>Expired</Title>
    <ExpiredMessage>It looks like this link has expired!</ExpiredMessage>
  </Wrapper>
}

const ConsentDenied = ({ onSubmit, onBack }: { onSubmit: () => void, onBack: () => void }) => {
  return <Wrapper>
  <Content>
    <Title>Deny Consent: Are You Sure?</Title>
    <Box>
      <Message>Choosing delete below will mean that all data relating to this user will be deleted immediately, including any writing or drawings they have created as well as their username and any other data. This cannot be undone.</Message>
      <ColumnActions>
        <Button size='small' theme='primary' onClick={onBack}>Back</Button>
        <Delete>
          <IconButton
            size='small'
            icon={<Close />}
            onClick={onSubmit}
            shadowColor={colors.red2}
            backgroundColor={colors.red3}
            color={colors.white}>
              Delete user
          </IconButton>
        </Delete>
      </ColumnActions>
    </Box>
    </Content>
  </Wrapper>
}


export default (props: IProps) => {
  const client = useApolloClient()
  const decodedToken = decodeJwt(props.token)
  const [consent, setConsent] = useState({ value: null })
  const [guardian, setGuardian] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [tAndCs, setTAndCs] = useState(false)

  useEffect(() => {
    if (typeof amplitude !== 'undefined') {
      amplitude.getInstance().logEvent('Dashboard: View consent page')
    }
  }, [])

  const submitConsent = async (value: boolean) => {
    const { data } = await client.mutate({
      mutation: SIGN_UP_AS_KID_CONSENT,
      variables: {
        token: props.token,
        consent: value
      }
    })
    if (typeof amplitude !== 'undefined') {
      amplitude.getInstance().logEvent(`Dashboard: ${value ? 'Give Consent' : 'Deny Consent'}`)
    }
    if (data.signUpAsKidConsent) {
      setConsent({ value })
      setSubmitted(true)
    }
  }

  if (new Date(decodedToken.exp) < new Date()) {
    return <ExpiredLink />
  }

  if (consent.value !== null && consent.value == true) {
    navigate(decodedToken.dashboardLink)
  }

  if (consent.value !== null && submitted && consent.value == false) {
    return <Wrapper>
    <Content>
      <Title>Account deleted</Title>
      <Box>
        <Message>Your child's account has been deleted</Message>
      </Box>
      </Content>
    </ Wrapper>
  }

  if(consent.value === false) return <ConsentDenied onSubmit={() => {submitConsent(false)}} onBack={() => {setConsent({value: null})}}/>

    return <Wrapper>
      <Grommet theme={theme}>
      <Content>
        <Title>Parental Consent</Title>
        <Box>
          <Message>
          Giving consent for your child to use Night Zookeeper means they will be able to do age-targeted activities, like writing, drawing, lessons and games, and to store their work and progress.          </Message>
          <Form>
            <Group>
              <CheckBox checked={guardian} onChange={e => setGuardian(e.target.checked)} />
              <Label>I am over 18 and the legal guardian of the child.</Label>
            </Group>
            <Group>
              <CheckBox checked={tAndCs} onChange={e => setTAndCs(e.target.checked)} />
              <Label>I agree that both I (parent or guardian) and the child agree to the following <Link onClick={() => window.open("https://www.nightzookeeper.com/terms-and-conditions", "_blank" )} >Terms and Conditions</Link> and <Link onClick={() => window.open("https://www.nightzookeeper.com/privacy", "_blank" )} >Privacy Policy.</Link></Label>
            </Group>
          </Form>
          <Info>
            By confirming consent, you give Night Zookeeper permission to contact you about their products and services.
          </Info>
          <Actions>
            <IconWrapper onClick={() => setConsent({ value: false })}>
              <IconButton
                size='small'
                shadowColor={colors.red2}
                backgroundColor={colors.red3}
                color={colors.white}
                icon={<Close />}
                >
                Deny</IconButton>
            </IconWrapper>
            <IconWrapper onClick={() => tAndCs && guardian && submitConsent(true)}>
              <IconButton
                size='small'
                theme='confirm'
                color={colors.red}
                icon={<Tick />}
                disabled={!tAndCs || !guardian}
                >
                Confirm</IconButton>
            </IconWrapper>
          </Actions>
        </Box>
      </Content>
      </Grommet>
    </Wrapper>
}


