import React from "react"
import ConsentPage from "../components/pages/ConsentPage"
import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout"

const ConsentPagePage = props => {
  if (typeof window === "undefined") return null

  const urlParams = new URLSearchParams(window.location.search)

  return <UnauthenticatedLayout>
    <ConsentPage {...props} token={urlParams.get('token')} />
  </UnauthenticatedLayout>
}

export default ConsentPagePage
